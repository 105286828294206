<template>
  <div>
    <breadcrumb name="Mansour Plus" title="Mansour Plus Information" subtitle="Edit"/>
    <div class="body">
      <h6 class="mandatory">Fields marked with (*) are mandatory</h6>
      <div>
        <div class="english">
          <vs-input label="English Title*" v-model="title_en"
                    size="medium"
                    class="input" :danger="flags.title_en" danger-text="This field is mandatory"/>
          <small class="label">English Description*</small>
          <ckeditor :editor="editor" v-model="text_en" :config="editorConfigEn" class="input" v-if="!showHTML"/>
          <textarea v-model="text_en" v-if="showHTML"></textarea>
          <small v-if="flags.text_en" style="color: red">This field is mandatory</small>
          <small v-if="flags.lengthExceeded_en" style="color: red">Text should't be more than 3000 characters</small>
        </div>
        <div class="arabic">
          <vs-input label="Arabic Title" v-model="title_ar"
                    size="medium"
                    class="input" :danger="flags.title_ar" danger-text="This field is mandatory"/>
          <small class="label">Arabic Description*</small>
          <ckeditor :editor="editor" v-model="text_ar" :config="editorConfigAr" class="input" v-if="!showHTML"/>
          <textarea v-model="text_ar" v-if="showHTML"></textarea>
          <small v-if="flags.text_ar" style="color: red">This field is mandatory</small>
          <small v-if="flags.lengthExceeded_ar" style="color: red">Text should't be more than 3000 characters</small>
        </div>
      </div>
      <div>
        <div class="clr"/>
      </div>
      <h6 v-if="noImage" class="error">There must be at least one image!</h6>
      <img-upload :images="images" @image_uploaded="saveImage" v-if="imageUpload"
                  title="Upload Images"/>

      <div class="optionBtns">
        <vs-button :disabled="invalidForm" class="mr-5 save" icon-pack="feather" icon="icon-save"
                   @click="confirmSaveData">Save
        </vs-button>
        <vs-button class="ml-5 cancel" type="border" color="primary" @click="$emit('cancel')">
          Reset
        </vs-button>
        <vs-button class="ml-5" type="border" @click="showHTML = !showHTML">{{showHTML ? "Hide HTML" : "Show HTML"}}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import { ChevronsRightIcon, HomeIcon } from "vue-feather-icons";
  import ImgUpload from "../../../../components/general/img-upload";
  import Breadcrumb from "../../../../components/general/breadcrumb";

  export default {
    name: "edit-rsa",
    components: { Breadcrumb, ImgUpload, HomeIcon, ChevronsRightIcon },
    data() {
      return {
        showHTML: false,
        imageUpload: false,
        baseImagesURL: "http://mansour-dev.s3.us-east-2.amazonaws.com/",
        title_ar: "",
        text_ar: "",
        title_en: "",
        text_en: "",
        images: [],
        editor: ClassicEditor,
        editorConfigAr: {
          placeholder: "تفاصيل",
          language: "ar"
        },
        editorConfigEn: {
          placeholder: "Details",
          language: "en"
        },
        imageData: {
          category: "config"
        },
        uploadImageHeaders: {
          "Access-Control-Allow-Origin": "* | http://192.241.234.75:9109"
        },
        flags: {
          title_ar: false,
          title_en: false,
          text_ar: false,
          text_en: false,
          lengthExceeded_ar: false,
          lengthExceeded_en: false
        },
        info: {}
      };
    },
    methods: {
      getData() {
        this.$vs.loading();
        this.$httpAbout.get(`config/mansour-plus`)
          .then(r => {
            this.info = r.data.data;
            this.title_ar = r.data.data.mansour_plus_title.ar;
            this.text_ar = r.data.data.mansour_plus_text.ar;
            this.title_en = r.data.data.mansour_plus_title.en;
            this.text_en = r.data.data.mansour_plus_text.en;
            this.images = r.data.data.mansour_plus_image;
            this.imageUpload = true;
            //console.log(this.info);
            this.$vs.loading.close();
          })
          .catch(() => {
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Couldn't Load Data!",
              color: "danger"
            });
            this.$vs.loading.close();
          });
      },
      saveImage(imagesArray) {
        this.images = imagesArray;
        console.log(this.images);
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      saveData() {
        this.$vs.loading();

        let newImages = [];

        for (let i = 0; i < this.images.length; i++) {
          let imageURL = this.images[i].split(this.baseImagesURL).pop();
          newImages.push(imageURL);
        }

        let payload = {
          mansour_plus_title: {
            ar: this.title_ar,
            en: this.title_en
          },
          mansour_plus_text: {
            ar: this.text_ar,
            en: this.text_en
          },
          images: newImages
        };

        console.log(payload);

        this.$httpAbout.post("config/mansour-plus", payload)
          .then(r => {
            this.$vs.loading.close();
            console.log(r);
            this.$vs.notify({
              title: "Saved",
              text: "Data is Saved Successfully",
              color: "primary"
            });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Error is occurred!\nCouldn't Save Data!",
              color: "danger"
            });
          });
      },
      successUpload(e) {
        //console.log(e);
        let imageURL = this.baseImagesURL + JSON.parse(e.target.response).data;
        this.rsa.roadside_assistance_image.push(imageURL);
        //console.log(this.rsa.roadside_assistance_image);
      },
      failUpload() {
        this.$vs.notify({
          title: "Error",
          text: "Failed to Upload Image!",
          color: "danger"
        });
      },
      confirm_delete_image(index) {
        this.$vs.dialog({
          type: "confirm",
          accept: () => this.delete_image(index),
          color: "danger",
          title: "Delete Image",
          text: "You are going to delete this imag"
        });
      },
      delete_image(index) {
        this.images.splice(index, 1);
      }
    },
    computed: {
      invalidForm() {
        if (this.flags.title_ar || this.flags.title_en || this.flags.text_ar || this.flags.text_en || this.flags.lengthExceeded_ar || this.flags.lengthExceeded_en || this.noImage) {
          return true;
        } else {
          return false;
        }
      },
      noImage() {
        return this.images.length === 0;
      }
    },
    watch: {
      title_ar(v) {
        v === "" ? this.flags.title_ar = true : this.flags.title_ar = false;
      },
      title_en(v) {
        v === "" ? this.flags.title_en = true : this.flags.title_en = false;
      },
      text_ar(v) {
        v.replace(/(<([^>]+)>)/ig, "") === "" ? this.flags.text_ar = true : this.flags.text_ar = false;
        v.replace(/(<([^>]+)>)/ig, "").length > 3000 ? this.flags.lengthExceeded_ar = true : this.flags.lenghtExceeded_ar = false;
      },
      text_en(v) {
        v.replace(/(<([^>]+)>)/ig, "") === "" ? this.flags.text_en = true : this.flags.text_en = false;
        v.replace(/(<([^>]+)>)/ig, "").length > 3000 ? this.flags.lengthExceeded_en = true : this.flags.lengthExceeded_en = false;
      }
    },
    mounted() {
      this.getData();
    }
  };
</script>

<style scoped lang="scss">
  @import "../../../basic-styles/mixins";

  %flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
  }

  .images {
    @include margin-y(30px);
    text-align: center;
  }

  .optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
      position: relative;
      left: 25px;
    }
  }

  .input {
    width: 100%;
    @include margin-y(30px)
  }

  .arabic, .english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
  }

  h1 {
    margin-bottom: 25px;
  }

  .inputs {
    @extend %flex;
  }

  ul {
    li {
      display: inline;
    }
  }

  .clr {
    clear: both;
  }

  $primaryColor: #054f7c;

  .breadcrumb {
    margin: 20px;
  }

  .back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
  }

  .main-component {
    color: $primaryColor;
  }

  .required {
    color: red;
    font-size: 1.5rem;
  }

  .mandatory {
    color: red;
  }

  .arabic, .english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }

  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px
  }

  .custom-class {
    color: $primaryColor;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
  }

  .label {
    color: #000;
  }

  .centered {
    text-align: center;
  }

  textarea {
    width: 100%;
    height: 350px;
  }
</style>
